import React, { FC } from "react";

import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";

const Privacy: FC = () => {
  return (
    <>
      <Seo title="Privacy Policy" />

      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    Privacy Policy
                  </h1>
                  <p
                    className="text-xl text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    Effective date: 06/02/2022
                  </p>
                </div>

                <article className="mt-10 mx-auto prose prose-dark lg:prose-xl">
                  <h2>1. Introduction</h2>
                  <p>Welcome to Wigwam.</p>
                  <p>
                    Wigwam (“we”, “us” or “our”) values your privacy. In this
                    Privacy Policy (“Policy”), we describe how we collect, use,
                    and disclose information that we obtain about visitors to
                    our website at <strong>https://wigwam.app</strong> (the
                    “Site”) and the services available through our Site,
                    including any mobile applications, web applications and
                    browser extensions (collectively, the “Services”), and how
                    we use and disclose that information.
                  </p>
                  <p>
                    By visiting the Site, or using any of our Services, you
                    agree that your personal information will be handled as
                    described in this Policy. Your use of our Site or Services,
                    and any dispute over privacy, is subject to this Policy
                    (including any applicable changes) and an applicable Terms
                    of Use, including its applicable limitations on damages and
                    provisions for the resolution of disputes.
                  </p>
                  <h2>2. Definitions</h2>
                  <h3>SERVICES</h3>
                  <p>
                    <strong>https://wigwam.app</strong> (the “Site”) and the
                    services available through this site, including any mobile
                    applications, web applications and browser extensions.
                  </p>
                  <p>
                    PERSONAL DATA means data about a living individual who can
                    be identified from that data (or from those and other
                    information either in our possession or likely to come into
                    our possession).
                  </p>
                  <p>
                    USAGE DATA is data collected automatically either generated
                    by the use of Services or from Services infrastructure
                    itself (for example, the duration of a page visit).
                  </p>
                  <p>
                    COOKIES are small files stored on your device (computer or
                    mobile device).
                  </p>
                  <p>
                    DATA CONTROLLER means a natural or legal person who (either
                    alone or jointly or in common with other persons) determines
                    the purposes for which and the manner in which any personal
                    data are, or are to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your data.
                  </p>
                  <p>
                    DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                  </p>
                  <p>
                    DATA SUBJECT is any living individual who is the subject of
                    Personal Data.
                  </p>
                  <p>
                    THE USER is the individual using our Services. The User
                    corresponds to the Data Subject, who is the subject of
                    Personal Data.
                  </p>
                  <h2>3. Information Collection and Use</h2>
                  <p>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </p>
                  <h2>4. Types of Data Collected</h2>
                  <h3>Personal Data</h3>
                  <p>
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (“Personal Data”). Personally
                    identifiable information may include, but is not limited to:
                  </p>
                  <ol type="a">
                    <li>Email address</li>

                    <li>Cookies and Usage Data</li>
                  </ol>
                  <p>
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at{" "}
                    <strong>info@wigwam.app</strong>.
                  </p>
                  <h3>Usage Data</h3>
                  <p>
                    We may also collect information that your browser sends
                    whenever you visit our Service or when you access Service by
                    or through a mobile device (“Usage Data”).
                  </p>
                  <p>
                    This Usage Data may include information such as your
                    computer's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    you visit, the time and date of your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </p>
                  <p>
                    When you access Service with a mobile device, this Usage
                    Data may include information such as the type of mobile
                    device you use, your mobile device unique ID, the IP address
                    of your mobile device, your mobile operating system, the
                    type of mobile Internet browser you use, unique device
                    identifiers and other diagnostic data.
                  </p>
                  <h3>Tracking Cookies Data</h3>
                  <p>
                    We use cookies and similar tracking technologies to track
                    the activity on our Service and we hold certain information.
                  </p>
                  <p>
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </p>
                  <p>
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </p>
                  <h3>Examples of Cookies we use on our website:</h3>
                  <ol type="a">
                    <li>
                      Session Cookies: We use Session Cookies to operate our
                      Service.
                    </li>
                    <li>
                      Preference Cookies: We use Preference Cookies to remember
                      your preferences and various settings.
                    </li>
                    <li>
                      Security Cookies: We use Security Cookies for security
                      purposes.
                    </li>
                    <li>
                      Advertising Cookies: Advertising Cookies are used to serve
                      you with advertisements that may be relevant to you and
                      your interests.
                    </li>
                  </ol>
                  <h3>In Wigwam application</h3>
                  <p>
                    In the Wigwam application we may track only these types of
                    anonymous data: anonymized clicks and pageview events. More
                    information you may find in the 13.2.1 point of this
                    agreement.
                  </p>
                  <h2>5. Use of Data</h2>
                  <p>Wigwam uses the collected data for various purposes:</p>
                  <ol type="a">
                    <li>to provide and maintain our Services;</li>

                    <li>to notify you about changes to our Services;</li>

                    <li>
                      to allow you to participate in interactive features of our
                      Services when you choose to do so;
                    </li>

                    <li>to provide customer support;</li>

                    <li>
                      to gather analysis or valuable information so that we can
                      improve our Services;
                    </li>

                    <li>to monitor the usage of our Services;</li>

                    <li>to detect, prevent and address technical issues;</li>

                    <li>
                      to fulfill any other purpose for which you provide it;
                    </li>

                    <li>
                      to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection;
                    </li>

                    <li>
                      to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </li>

                    <li>
                      to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information;
                    </li>

                    <li>
                      in any other way we may describe when you provide the
                      information;
                    </li>

                    <li>for any other purpose with your consent.</li>
                  </ol>
                  <h2>6. Retention of Data</h2>
                  <p>
                    We will retain your Personal Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy.
                    We will retain and use your Personal Data to the extent
                    necessary to comply with our legal obligations (for example,
                    if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal
                    agreements and policies.
                  </p>
                  <p>
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Services, or
                    we are legally obligated to retain this data for longer time
                    periods.
                  </p>
                  <h2>7. Transfer of Data</h2>
                  <p>
                    Your information, including Personal Data, may be
                    transferred to - and maintained on - computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ from those of your jurisdiction.
                  </p>
                  <p>
                    If you are located outside the United States and choose to
                    provide information to us, please note that we transfer the
                    data, including Personal Data, to the United States and
                    process it there.
                    <br />
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </p>
                  <p>
                    Wigwam will take all the steps reasonably necessary to
                    ensure that your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of your Personal
                    Data will take place to an organisation or a country unless
                    there are adequate controls in place including the security
                    of your data and other personal information.
                  </p>
                  <h2>8. Disclosure of Data</h2>
                  <p>
                    We may disclose personal information that we collect, or you
                    provide:
                  </p>
                  <ol type="a">
                    <li>Business Transaction.</li>
                    <li>
                      If we or our subsidiaries are involved in a merger,
                      acquisition or asset sale, your Personal Data may be
                      transferred.
                    </li>

                    <li>Other cases. We may disclose your information also:</li>

                    <li>to our subsidiaries and affiliates;</li>
                  </ol>
                  <h2>9. Security of Data</h2>
                  <p>
                    The security of your data is important to us but remember
                    that no method of transmission over the Internet or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                  </p>
                  <h2>
                    10. Your Data Protection Rights Under General Data
                    Protection Regulation (GDPR)
                  </h2>
                  <p>
                    If you are a resident of the European Union (EU) and
                    European Economic Area (EEA), you have certain data
                    protection rights, covered by GDPR. - See more at{" "}
                    <ExternalLink>
                      https://eur-lex.europa.eu/eli/reg/2016/679/oj
                    </ExternalLink>
                    .
                  </p>
                  <p>
                    We aim to take reasonable steps to allow you to correct,
                    amend, delete, or limit the use of your Personal Data.
                  </p>
                  <p>
                    If you wish to be informed what Personal Data we hold about
                    you and if you want it to be removed from our systems,
                    please email us at <strong>info@wigwam.app</strong>. In
                    certain circumstances, you have the following data
                    protection rights:
                  </p>
                  <ol type="a">
                    <li>
                      the right to access, update or to delete the information
                      we have on you;
                    </li>
                    <li>
                      the right of rectification. You have the right to have
                      your information rectified if that information is
                      inaccurate or incomplete;
                    </li>
                    <li>
                      the right to object. You have the right to object to our
                      processing of your Personal Data;
                    </li>
                    <li>
                      the right of restriction. You have the right to request
                      that we restrict the processing of your personal
                      information;
                    </li>
                    <li>
                      the right to data portability. You have the right to be
                      provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                    </li>
                    <li>
                      the right to withdraw consent. You also have the right to
                      withdraw your consent at any time where we rely on your
                      consent to process your personal information;
                    </li>
                  </ol>
                  <p>
                    Please note that we may ask you to verify your identity
                    before responding to such requests.
                    <br />
                    Please note, we may not be able to provide Services without
                    some necessary data.
                    <br />
                    You have the right to complain to a Data Protection
                    Authority about our collection and use of your Personal
                    Data. For more information, please contact your local data
                    protection authority in the European Economic Area (EEA).
                  </p>
                  <h2>
                    11. Your Data Protection Rights under the California Privacy
                    Protection Act (CalOPPA)
                  </h2>
                  <p>
                    CalOPPA is the first state law in the nation to require
                    commercial websites and online services to post a privacy
                    policy. The law's reach stretches well beyond California to
                    require a person or company in the United States (and
                    conceivable the world) that operates websites collecting
                    personally identifiable information from California
                    consumers to post a conspicuous privacy policy on its
                    website stating exactly the information being collected and
                    those individuals with whom it is being shared, and to
                    comply with this policy. - See more at:{" "}
                    <ExternalLink>
                      https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                    </ExternalLink>
                  </p>
                  <h3>According to CalOPPA we agree to the following:</h3>
                  <ol type="a">
                    <li>users can visit our site anonymously;</li>
                    <li>
                      our Privacy Policy link includes the word “Privacy”, and
                      can easily be found on the page specified above on the
                      home page of our website;
                    </li>
                    <li>
                      users will be notified of any privacy policy changes on
                      our Privacy Policy Page;
                    </li>
                    <li>
                      users are able to change their personal information by
                      emailing us at <strong>info@wigwam.app</strong>.
                    </li>
                  </ol>
                  <p>Our Policy on “Do Not Track” Signals:</p>
                  <p>
                    We honor Do Not Track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track browser
                    mechanism is in place. Do Not Track is a preference you can
                    set in your web browser to inform websites that you do not
                    want to be tracked. You can enable or disable Do Not Track
                    by visiting the Preferences or Settings page of your web
                    browser.
                  </p>
                  <h2>12. Service Providers</h2>
                  <p>
                    We may employ third party companies and individuals to
                    facilitate our Service (“Service Providers”), provide
                    Service on our behalf, perform Service-related services or
                    assist us in analysing how our Services are used.
                  </p>
                  <p>
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </p>
                  <h2>13. Analytics</h2>
                  <p>
                    We may use third-party Service Providers to monitor and
                    analyze the use of our website and our applications.
                  </p>
                  <h3>13.1 Google Analytics on the website wigwam.app</h3>
                  <p>
                    Google Analytics is a web analytics service offered by
                    Google that tracks and reports website traffic. Google uses
                    the data collected to track and monitor the use of our
                    Service. This data is shared with other Google services.
                    Google may use the collected data to contextualise and
                    personalise the ads of its own advertising network.
                    <br />
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:{" "}
                    <ExternalLink>
                      https://policies.google.com/privacy?hl=en
                    </ExternalLink>
                  </p>
                  <p>
                    We also encourage you to review Google's policy for
                    safeguarding your data:{" "}
                    <ExternalLink>
                      https://support.google.com/analytics/answer/6004245
                    </ExternalLink>
                    .
                  </p>
                  <h3>13.2.1 Amplitude in the Wigwam application</h3>
                  <p>
                    Amplitude is a product analytics platform that helps
                    businesses to track visitors with the help of collaborative
                    analytics. The platform uses event generated reports to
                    understand users' interactions with products and provides
                    insights to accelerate work on a real-time basis. Amplitude
                    privacy notice may be found here:{" "}
                    <ExternalLink>https://amplitude.com/privacy</ExternalLink>.
                  </p>
                  <h3>13.2.2. What events we are tracking with Amplitude:</h3>
                  <p>
                    We are improving the <strong>Wigwam application</strong>{" "}
                    constantly and to better understand which section of the
                    wallet should be updated, we gather anonymous usage data.
                    This data will be used only for improving the usability of
                    the Wigwam application.
                  </p>
                  <strong>What we track:</strong>
                  <br />
                  <ul>
                    <li>Anonymized click</li>
                    <li>Pageview events</li>
                  </ul>
                  <strong>We NEVER track:</strong>
                  <br />
                  <ul>
                    <li>Keys</li>
                    <li>Addresses</li>
                    <li>Transactions</li>
                    <li>Balances</li>
                    <li>Hashes</li>
                    <li>IP</li>
                    <li>Any personal information</li>
                  </ul>
                  <p>
                    You may always turn off the analytics tracking feature in
                    your wallet settings.
                  </p>
                  <h2>14. CI/CD tools</h2>
                  <p>
                    We may use third-party Service Providers to automate the
                    development process of our Services.
                  </p>
                  <ul>
                    <li>GitHub</li>
                    <li>GitHub is provided by GitHub, Inc.</li>
                    <li>
                      GitHub is a development platform to host and review code,
                      manage projects, and build software.
                    </li>
                  </ul>
                  <p>
                    For more information on what data GitHub collects for what
                    purpose and how the protection of the data is ensured,
                    please visit GitHub Privacy Policy page:{" "}
                    <ExternalLink>
                      https://help.github.com/en/articles/github-privacy-statement
                    </ExternalLink>
                    .
                  </p>
                  <h2>15. Behavioral Remarketing</h2>
                  <p>
                    Wigwam website uses remarketing services to advertise on
                    third party websites to you after you visited our website.
                    We and our third-party vendors use cookies to inform,
                    optimize and serve ads based on your past visits to our
                    website.
                  </p>
                  <h3>Google Ads (AdWords)</h3>
                  <p>
                    Google Ads (AdWords) remarketing service is provided by
                    Google Inc.
                    <br />
                    You can opt-out of Google Analytics for Display Advertising
                    and customize the Google Display Network ads by visiting the
                    Google Ads Settings page:{" "}
                    <ExternalLink>
                      http://www.google.com/settings/ads
                    </ExternalLink>
                    Google also recommends installing the Google Analytics
                    Opt-out Browser Add-on -
                    <ExternalLink>
                      https://tools.google.com/dlpage/gaoptout
                    </ExternalLink>{" "}
                    - for your web browser. Google Analytics Opt-out Browser
                    Add-on provides visitors with the ability to prevent their
                    data from being collected and used by Google Analytics. For
                    more information on the privacy practices of Google, please
                    visit the Google Privacy Terms web page:
                    <ExternalLink>
                      https://policies.google.com/privacy?hl=en
                    </ExternalLink>
                  </p>
                  <h3>Twitter</h3>
                  <p>
                    Twitter remarketing service is provided by Twitter Inc.
                    <br />
                    You can opt-out from Twitter's interest-based ads by
                    following their instructions:{" "}
                    <ExternalLink>
                      https://support.twitter.com/articles/20170405
                    </ExternalLink>
                    .
                    <br />
                    You can learn more about the privacy practices and policies
                    of Twitter by visiting their Privacy Policy page:{" "}
                    <ExternalLink>https://twitter.com/privacy</ExternalLink>.
                  </p>
                  <h3>Facebook</h3>
                  Facebook remarketing service is provided by Facebook Inc.
                  <br />
                  You can learn more about interest-based advertising from
                  Facebook by visiting this page:{" "}
                  <ExternalLink>
                    https://www.facebook.com/help/164968693837950
                  </ExternalLink>
                  .
                  <br />
                  To opt-out from Facebook's interest-based ads, follow these
                  instructions from Facebook:{" "}
                  <ExternalLink>
                    https://www.facebook.com/help/568137493302217
                  </ExternalLink>
                  .
                  <br />
                  Facebook adheres to the Self-Regulatory Principles for Online
                  Behavioural Advertising established by the Digital Advertising
                  Alliance. You can also opt-out from Facebook and other
                  participating companies through the Digital Advertising
                  Alliance in the USA{" "}
                  <ExternalLink>http://www.aboutads.info/choices/</ExternalLink>
                  , the Digital Advertising Alliance of Canada in Canada{" "}
                  <ExternalLink>http://youradchoices.ca/</ExternalLink> or the
                  European Interactive Digital Advertising Alliance in Europe{" "}
                  <ExternalLink>http://www.youronlinechoices.eu/</ExternalLink>,
                  or opt-out using your mobile device settings.
                  <br />
                  For more information on the privacy practices of Facebook,
                  please visit Facebook's Data Policy:{" "}
                  <ExternalLink>
                    https://www.facebook.com/privacy/explanation
                  </ExternalLink>
                  .<h2>16. Links to Other Sites</h2>
                  <p>
                    Our Services may contain links to other sites that are not
                    operated by us. If you click a third party link, you will be
                    directed to that third party's site. We strongly advise you
                    to review the Privacy Policy of every site you visit.
                    <br />
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                  <h2>17. Children's Privacy</h2>
                  <p>
                    Our Services are not intended for use by children under the
                    age of 13 (“Children”). We do not knowingly collect
                    personally identifiable information from Children under 13.
                    If you become aware that a Child has provided us with
                    Personal Data, please contact us. If we become aware that we
                    have collected Personal Data from Children without
                    verification of parental consent, we take steps to remove
                    that information from our servers.
                  </p>
                  <h2>18. Changes to This Privacy Policy</h2>
                  <p>
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                    <br />
                    We will let you know via email and/or a prominent notice on
                    our Services, prior to the change becoming effective and
                    update “effective date” at the top of this Privacy Policy.
                    <br />
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                  <h2>19. Contact Us</h2>
                  <p>
                    If you have any questions about this Privacy Policy, please
                    contact us by email: <strong>info@wigwam.app</strong>.
                  </p>
                </article>
              </div>
            </div>
          </section>
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default Privacy;

const ExternalLink: FC<{ children: string }> = ({ children }) => (
  <a href={children} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
